<ng-container #newMasterSearch>
    <h3 class="d-inline" (click)="isCollapse.search = !isCollapse.search" role="button">   
        <i class="far mr-2" [ngClass]="{'fa-chevron-up': !isCollapse.search, 'fa-chevron-down': isCollapse.search}"></i>
        {{'APP.SEARCH.TITLE_SEARCH' | translate: {type: searchType} }}
    </h3>                        
    <app-panel-new-entity-search class="mt-3"
                                [searchType]="searchType"
                                [collapse]="isCollapse.search" 
                                [isAnimated]="true"
                                [skipRestrictionsFilters]="skipRestrictionsFilters"
                                (search)="searchNewMaster($event)"></app-panel-new-entity-search>
</ng-container>                 
<ng-container #newMasterList>                        
    <div [hidden]="!masterListSearched" class="mt-3">
        <h3 class="d-inline" (click)="isCollapse.list = !isCollapse.list" role="button">   
            <i class="far mr-2" [ngClass]="{'fa-chevron-up': !isCollapse.list, 'fa-chevron-down': isCollapse.list}"></i>
            {{'APP.SEARCH_MASTER.TITLE_SEARCHED_LIST' | translate}} <small>({{totalSearchData}})</small>
        </h3>     
        <app-bulk-card-radio-list *ngIf="masterListSearched && masterListSearched.length > 0"
                                cardName="newMasterItem"
                                class="mt-3"
                                heightClass="small"
                                isExternalDetail="true"
                                [itemDetailTemplate]="itemDetailTemplate"
                                [lookupList]="masterListSearched"
                                [lookupCount]="totalSearchData"
                                (setSelectedRecords)="setSelectedMaster($event)"
                                (setMoreRecords)="setMoreNewMasterRecords()">        
        </app-bulk-card-radio-list> 
        <app-table-empty-result *ngIf="masterListSearched && masterListSearched.length === 0" 
                                [message]="''"
                                [heading]="'APP.RESULTS.NOT_FOUND'"></app-table-empty-result>
    </div> 
</ng-container>