import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { JwtTokenManagementStore } from '@app-auth/services/jwt-token-management.store';

@Directive({
  selector: '[iamSecurity]'
})
export class OnyxIamSecurityDirective {

  private _iamSecurity: string;
  private _iamNotSecurity: string;

  private permissionList: string[];

  constructor(private templateRef: TemplateRef<any>,
              private jwtTokenManagementStore: JwtTokenManagementStore,
              private viewContainer: ViewContainerRef) {
  }

  get iamSecurity() {
    return this._iamSecurity;
  }


  @Input()
  set iamSecurity(iamSecurity: string | string[]) {
    let isDisabled = true;
    if (!iamSecurity) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
       this.permissionList = this.jwtTokenManagementStore.getLocalPermissions();

       if (this.permissionList && (this.permissionList.length > 0)) {
          if (typeof iamSecurity === 'string') {
            isDisabled = !this.theUserCanSeeTheComponent(iamSecurity);
          }
          else {
            const existPermission = [];
            iamSecurity.map(item => {
              existPermission.push(this.theUserCanSeeTheComponent(item));
            });
            isDisabled = existPermission.findIndex(i => i === true) === -1;
          }
        }

       if (isDisabled)  {
          this.viewContainer.clear();
        } else {
          this.viewContainer.createEmbeddedView(this.templateRef);
        }
      }
  }

  public theUserCanSeeTheComponent(permission: string): boolean {
    return this.permissionList.includes(permission);
  }

}
